import React from 'react'
import Layout from 'components/layout'
import Helmet from 'react-helmet'
import SEO from 'components/seo/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Helmet>
      <body className="k-perma-header" />
    </Helmet>
    <div className="k-py-menu" />
    <div className="container-fluid">
      <div className="k-section">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist...</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
