import React, { useEffect } from 'react';
import NotFoundPage from './404';

/*
  This page is to act as way to remove Google Analytics tracking for internal use
*/

const DontTrackMe = () => {
  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window.localStorage;
    if (windowGlobal) {
      localStorage.setItem('dtm', true);
    }
  }, []);

  return <NotFoundPage/>;
};

export default DontTrackMe;